/* Bottom navigation container */
.bottomNav{
    display: none;
}

@media (max-width: 980px) {

.bottomNav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    border-top: 1px solid #ddd;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  /* Navigation item */
  .navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #777;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  /* Hover effect */
  .navItem:hover {
    color: #005dd6; /* Blue color on hover */
  }
  
  /* Icon styles */
  .icon {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
}
  
  /* Mobile responsiveness */
@media (max-width: 480px) {
    .bottomNav {
      height: 50px;
    }
    .navItem {
      font-size: 10px;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }
  