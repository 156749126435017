@import '../../public/geomanist/geomanistRegular/stylesheet.css';
@import '../../public/geomanist/geomanistMedium/stylesheet.css';
@import '../../public/geomanist/geomanistBook/stylesheet.css';

.originalPos {
	/* position: absolute; */
	margin-top: 3.55%;
	/* margin-left: 10.2%; */
	transition: 1s;
	margin-top: 48px;
	margin-left: auto;
}
/* .footerMargin {
	margin-top: 80px;
} */

.shiftPos {
	/* position: absolute;
	margin-top: 3.55%;
	margin-left: 1%;
	transition: 1s;
	/* text-transform: lowercase; */
	/* transition: 1s; */
	margin-top: 48px;
}

/* .homeMargin { */
/* padding-top: 3.07%; */
/* margin-top: 34px; */
/* margin-top: 120px; */
/* } */

.popularPadding {
	padding-right: 30px;
}

.cardrowMargin {
	margin-top: 64px;
	margin-left: 0px;
}

.cardtopMargin {
	margin-top: 25px;
	/* padding-right: 15px;
	padding-left: 15px; */
}

.heading {
	font-family: 'geomanistmedium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.6px;
	color: #1e1f24;
	margin-left: 0px;
	margin-bottom: 24px;
	text-transform: capitalize;
}

.subHeading {
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.6px;
	color: #626777;
	margin-left: 0px;
}

.popularEtf {
	margin-top: 44px;
}

.popularStock {
	margin-top: 14px;
}
.row {
	margin-left: 0px;
	margin-right: 0px;
}
.responsiveUi {
	padding-right: 30px;
}

.grapherSlider :global(.slick-dots li) {
	margin-right: 20px;
}
.grapherSlider :global(.slick-dots) {
bottom:-70px
}
.grapherSlider :global(.slick-next) {
	display: none !important;
}

.trendingSlider :global(.slick-list) {
	padding-left: 0px !important;
}

.themeSlider :global(.slick-next) {
	display: none !important;
}

.trendingSlider :global(.slick-slide) {
	padding-right: 16px !important;
}

.trendingStocksSlider :global(.slick-list) {
	/* width: 290px !important; */
	padding-left: 0px !important;
}

.trendingStocksSlider :global(.slick-slide) {
	padding-right: 16px !important;
}

.themeSlider :global(.slick-list) {
	padding-left: 0px !important;
}

/* .newsSlider:global(.slick-slide){
	height: 200px;
} */
.newsSlider :global(.slick-next) {
	display: none !important;
}
/* .newsSlider :global(.slick-list) {
	padding-left: 0px !important;
} */

.mobileMargin {
	margin-bottom: 35px;
}

.mobileMargin52 {
	margin-bottom: 24px;
}

.newsMargin {
	margin-top: 48px;
}

.firstTimeBigBanner{
  width: 100%;
	background: #F7F7F8;
	height: 330px;
	border: 1px solid #efeff1;
	box-sizing: border-box;
	border-radius: 16px;
}

.firstTimeBanner {
	width: 100%;
	background: #F7F7F8;
	height: 268px;
	border: 1px solid #efeff1;
	box-sizing: border-box;
	border-radius: 16px;
	/* padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 15px; */
}
.graphrowMargin{
  margin-top: 70px;
	margin-left: 0px;
}
.seeAllButton{
  font-family: 'geomanistBook';
  color: #005DD6;
  letter-spacing: 0.6px;
  font-size: 16px;
  cursor: pointer;
}
.seeAllArrow{
  /* font-size: 10px; */
  margin-left:11px;
  cursor: pointer;
}

.chartBorder{
  border-bottom: 1px solid #EFEFF1;
  padding-bottom: 50px;
}

.popularMargin{
  margin-bottom: 32px;
}

.AllYearCardPadding{
  margin-top: 80px;
  margin-left: 0px;
}

.mobileMarginPopularStocks{
  margin-top: 0px;
}

.topGainerLoserPadding{
  margin-top:80px;
}
.legendGraph{
  margin-top: 4px;
  background: #0DC014;
  border-radius: 4px;
  width: 16px;
  height: 16px;
}
.beneficiaryBannerContainer{
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  background-color: #F7F7F8;
  border-radius: 8px;
  font-family: 'geomanistMedium';
  width: 100%;
  margin-bottom: 48px;
}
.signatureText{
	padding-top:6px;
	font-family: 'geomanistregular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #767C8F;
}
.cetfBannerContainer{
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  background-color: #D7E3F1;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 48px;
}
.cetfText{
  font-family: 'geomanistRegular';
  font-size: 25px;
}
.cetfDistributor{
  font-family: 'geomanistRegular';
  font-size: 10px;
  display: block;
  width: 100%;
  text-align: center;
}
.cetfDiscalimer{
  font-family: 'geomanistRegular';
  font-size: 9px;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}
.cetfFlex{
  flex-flow: row wrap;
}
.cetfImage{
  width: 100%;
}
.cetfButton{
  font-size: 14px;
  font-family: 'geomanistRegular';
  height: 40px;
}
.panBannerContainer{
  align-items: unset;
}
.notificationModal :global(.modal-content){
	border-radius: 16px !important;
	overflow: hidden;
	max-height: 80vh;
}
.notificationHeading {
	font-family: "geomanistmedium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #1e1f24;
	padding-left: 16px;
	padding-right: 16px;
}
.notificationModalOuter {
	overflow: auto;
	padding: 0px 16px;
}
.notificationModalBody {
	font-family: 'geomanistRegular';
	font-size: 12px;
}

.notificationFooter{
	width: 100%;
	background: rgb(244, 244, 244);
	padding: 16px 32px;
	display: flex;
	justify-content: space-between;
	gap: 16px;
	font-family: 'geomanistRegular';
	font-size: 14px;
	display: flex;
	flex-direction: row;
}
.notificationBtn {
    height: 48px;
    text-align: center;
    justify-content: center;
    background: #0061D3;
    border-radius: 8px;
    font-family: "geomanistmedium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #fff;
	padding: 10px 20px;
	border: none;
	min-width: 156px;
	display: flex;
	align-items: center;
	margin-top: 20px;
}
.notificationAcceptError{
	margin-top: 4px;
	color: #FA5B05
}
.notificationDisableBtn{
	cursor: default !important;
}
.tryAgain{
	color: #0061D3;
	margin-left: 6px;
	white-space: nowrap;
}
.spinnerColor{
	margin-right: 6px;
    border: .25em solid #FFFFFF;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
}
.warningImg{
	margin-right: 6px;
}
@media (max-width: 350px){
	.mobilePadding{
		padding: 0 10px;
	}
}
@media (min-width: 320px) and (max-width: 480px) {
  .cetfDistributor{
    font-family: 'geomanistRegular';
    font-size: 8px;
    display: block;
    width: 100%;
    text-align: center;
  }
  .cetfDiscalimer{
    font-family: 'geomanistRegular';
    font-size: 7px;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .cetfButton .btn-primary{
    font-size: 10px !important;
  }
  .cetfText{
    font-size: 20px;
  }
  .beneficiaryBannerContainer{
    margin-bottom: 16px;
  }
  .topGainerLoserPadding{
    margin-top:0px;
  }
  .AllYearCardPadding{
    margin-top: 40px;
    margin-left: 0px;
  }
  .graphrowMargin{
    margin-top:0px;
    margin-bottom: 90px;
  }
  .compare{
    font-family: 'geomanistregular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #1E1F24;
  margin-bottom: 0px;
  }

  .firstTimeBigBanner{
    width: 100%;
		background: #e5e5e5;
		height: auto;
		border: 1px solid #efeff1;
		box-sizing: border-box;
		border-radius: 16px;
  }

	.firstTimeBanner {
		width: 100%;
		background: #e5e5e5;
		height: auto;
		border: 1px solid #efeff1;
		box-sizing: border-box;
		border-radius: 16px;
		/* padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
    padding-top:0px; */
	}
	.grapherSlider {
		height: 250px;
	}
	/* .trendingSlider {
		height: 250px;
	} */
  	.trendingSlider :global(.slick-slide) {
		/* width: 256px !important; */
		padding-right: 16px !important;
	}
	/* .trendingStocksSlider {
		height: 250px;
	} */
  /* .trendingStocksSlider :global(.slick-slide) {
    width: 188px !important;
  } */


	.originalPos {
		position: relative;
		margin-left: 0px;
		margin-top: 16px;
		transition: 0s;
		padding-left: 15px;
		padding-right: 15px;
	}
	.cardrowMargin {
    margin-top: 47px;
    margin-left: 0px;
  }
	.footerMargin {
		margin-top: 48px;
	}

	.newsMargin {
		margin-top: 10px;
	}

	.mobileMargin {
		margin-bottom: 16px;
	}
	.mobileMargin52 {
		margin-bottom: 16px;
    margin-top: 47px;
	}

	.marginStocks {
		margin-top: 47px;
	}
	.responsiveMargin {
		margin-left: 0px;
		margin-right: 0px;
	}
	.popularPadding {
		padding-right: 15px;
	}
	.heading {
		font-size: 20px;
	}
	.subHeading {
		font-size: 14px;
	}
	.responsiveUi {
		margin-top: 64px;
	}
	.customPaging {
		width: 40px;
		height: 4px;
		background: #1e1f24;
		border-radius: 8px;
		margin-right: 24px;
	}
	.customPagingInactive {
		width: 16px;
		height: 4px;
		background: #626777;
		opacity: 2;
		border-radius: 8px;
		/* position: absolute;
		right: -18px; */
	}
	.perform {
		margin-top: 32px;
		font-family: 'geomanistmedium';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		text-transform: capitalize;
		letter-spacing: 0.6px;

		color: #1e1f24;
	}
  .cardtopMargin {
    margin-top: 25px;
    margin-left: 5px;
  }
  .popularMargin{
    margin-bottom: 16px;
  }
  .mobileMarginPopularStocks{
    margin-top: 47px;
  }
  .weekLowMobileMargin{
    margin-top:47px;
  }
  .trendingSlider :global(.slick-list) {
    min-height: 0px !important;
    height: 100% !important;
	}
  .trendingStocksSlider :global(.slick-list) {
    min-height: 0px !important;
    height: 100% !important;
	}
  .themeSlider :global(.slick-list) {
    min-height: 0px !important;
    height: 100% !important;
	}
}

@media (min-width: 500px) and (max-width: 990px) {
	.grapherSlider {
		height: 250px;
	}
	/* .trendingSlider {
		height: 250px;
	} */
	.trendingStocksSlider {
		height: 100%;
	}
	.themeSlider {
		height: 350px;
	}
	.trendingSlider :global(.slick-slide) {
		/* width: 290px !important; */
		padding-right:16px !important;
	}
	/* .newsSlider :global(.slick-slide) {
		width: 200px !important;
	} */
	.popularPadding {
		padding-right: 30px;
	}
	.themeSlider :global(.slick-slide) {
		width: 240px !important;
	}
	.firstTimeBanner {
		height: auto;
	}
  .firstTimeBigBanner{
    height: auto;
  }
  .AllYearCardPadding{
    margin-top: 80px;
    margin-left: 0px;
  }
  .mobileMargin52 {
		margin-bottom: 16px;
    margin-top: 47px;
	}
  .topGainerLoserPadding{
    margin-top:0px;
  }
  .trendingStocksSlider :global(.slick-list) {
    min-height: 0px !important;
    height: 100% !important;
	}
}

@media (min-width: 490px) and (max-width: 766px) {

  .weekLowMobileMargin{
    margin-top:47px;
  }
  .mobileMarginPopularStocks{
    margin-top: 47px;
  }
}

@media (min-width: 1000px) and (max-width: 1540px) {
	/* .trendingSlider {
		height: 250px;
	} */
	.shiftPos {
		margin-left: 2%;
		transition: 1s;
	}
  .trendingWeb{
    display: block;
  }
}

@media (max-width: 700px) {
  .sideBarMobileHide{
    display: none;
  }
  .trendingWeb{
    display: none;
  }
  .weekLowMobileMargin{
    margin-top:47px;
  }
}

@media(min-width:700px) {
	.sideBarMobileHide {
	  display: block;
	}
  }

@media (max-width:990px) {
  .trendingWeb{
    display: none;
  }
  .trendingMobile{
    display: flex;
  }
}

.firstTimeBanner {
	margin-top: 144px;
}

@media(min-width:380px) {
	.firstTimeBanner {
		margin-top: 124px;
	}
}

@media(min-width:992px) {
	.firstTimeBanner {
		margin-top: 104px;
	}
}

@media(max-width:768px){
	.notificationFooter{
		flex-direction: column;
	}
}


