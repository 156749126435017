/* Beneficiary */
.instructionContainer{
    padding: 24px 0px 42px 0px;
    /* fixed footer size */
    margin-bottom:86px;
}
.instructionHeader{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 0px 4px 16px;
    border-bottom: 1px solid #EFEFF1;
}
.instructionHeader .bankName{
    font-family: "geomanistBook";
    color: #3C404A;
    margin-bottom: 8px;
}
.instructionHeader .helper{
    font-family: "geomanistRegular";
    color: #767C8F;
}
.instructionHeader .downloadPDF{
    font-family: "geomanistBook";
    margin-top: 20px;
    background: #0061D3;
    padding: 10px 16px 10px 12px;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    width: 166px;
    outline-color: #0061D3;
}
.instructionHeader .downloadPDF:focus, .instructionHeader .downloadPDF:hover{
    background:#004EA9;
}
.downloadPDF img{
    margin-right: 6px;
}
.instructionBody{
    margin-top: 24px;
}
.limitNote{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #000000;
    background: #EFEFF1;
    border-radius: 8px;
    padding: 16px;
    gap: 12px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
}
.loadingContainer{
    display: flex;
    justify-content: center;
    margin-top: 48px;
    align-items: center;
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #0061D3;
}
.spinnerColor{
    border: .25em solid #FFFFFF;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
.spinnerColorBlue{
    border: .25em solid #0061D3;
    border-right-color: #CCDFF6;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
.tableLoading{
    border: .25em solid #0061D3;
    border-right-color: #CCDFF6;
}

/* Download Instruction */
.downloadError {
	font-family: 'geomanistBook';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	text-transform: capitalize;
	color: #005dd6;
    display: flex;
    flex-direction: row;
    padding-top: 2px;
}
.link{
    cursor: pointer;
}
.link:hover{
    text-decoration: underline;
}

/* Success */
.successContainer{
    height: calc(100vh - 146px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.successMsg{
    font-family: 'geomanistBook';
    font-size: 14px;
    color: #3C404A;
    margin-bottom: 32px;
}
.date{
    color: #767C8F;
    font-size: 14px;
    font-family: 'geomanistBook';
    margin-bottom: 34px;
}
.textLink{
    font-size: 14px;
    font-family: 'geomanistBook';
    color: #0061D3;
    cursor: pointer;
}

/* Transaction */
.label{
    font-family: "geomanistRegular";
    font-size: 12px;
    color: #3C404A;
    font-weight: 600;
    /* margin-bottom: 4px; */
}
.input{
    border: 1.5px solid #D3D4DA;
    border-radius: 6px;
    padding: 10px 12px;
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #3C404A;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.input:hover, .input:focus-within{
    border: 1.5px solid #3C404A;
}
.input input::placeholder{
    font-family: 'geomanistRegular';
    font-size: 14px;
    color: #767C8F;
}
.errorInput:hover, .errorInput:focus-within{
    border: 1.5px solid #FA274A;
}
.error{
    font-family: 'geomanistRegular';
    font-size: 12px;
    color: #FA274A;
}
.footerContainer{
    position: fixed;
    display: flex;
    bottom: 0;
    border-top: 1px solid #EFEFF1;
    padding: 24px;
    margin-left: -24px;
    gap:16px;
    background: #FFFFFF;
    width: 100%;
}
.footerContainer .cancelBtn{
    font-family: "geomanistBook";
    padding: 10px 20px;
    border-radius: 8px;
    color: #0061D3;
    border: 1.5px solid #0061D3;
    background: #FFFFFF;
    outline-color: #0061D3;
}
.footerContainer .cancelBtn:focus, .footerContainer .cancelBtn:hover{
    border: 1.5px solid #004EA9;
    outline-color: #004EA9;
    color: #004EA9;
}
.footerContainer .continueBtn{
    font-family: "geomanistBook";
    background: #0061D3;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    outline-color: #0061D3;
    display: flex;
    align-items: center;
}
.footerContainer .continueBtn:focus, .footerContainer .continueBtn:hover{
    background: #004EA9;
}
.footerContainer .tertiaryBtn {
    font-family: "geomanistBook";
    background: none;
    border: none;
    color: #0061D3;
    outline: none;
    padding: 10px;
}

.footerContainer .tertiaryBtn:focus, .footerContainer .tertiaryBtn:hover {
    outline: none;
    color: #004EA9;
}

.successDesc{
  font-family: 'geomanistregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #767C8F;
}

.secondaryBtn{
  width:100%;
  font-family: "geomanistBook";
  color: #0061D3;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1.5px solid #0061D3;
  background: #FFFFFF;
  outline-color: #0061D3;
  display: flex;
  justify-content:center;
  line-height:12px;
}

.secondaryBtn:focus,.secondaryBtn:hover{
  background: #FFFFFF;
}
